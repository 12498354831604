<template>
  <div class="">
    <van-sticky>
      <van-nav-bar title="Já foi concluído" left-arrow @click-left="onClickLeft" />
    </van-sticky>

    <!-- 支付页面 -->
    <div class="pay">
      <iframe src="" frameborder="0" ref="myFrame"></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      radio: null,
      // iframeSrc: ''
    }
  },
  // 计算属性
  computed: {},
  watch: {},
  mounted() {
    this.loadIframe()
  },
  methods: {
    async loadIframe() {
      const iframe = this.$refs.myFrame;
      const headers = JSON.parse(localStorage.getItem('headers'))
      const response = await fetch('https://cab.freemovies88.com/card/list', { headers })
      const blob = await response.blob()
      const fileReader = new FileReader()
      fileReader.onload = () => {
        const objectUrl = URL.createObjectURL(blob);
        iframe.src = objectUrl;
      }
      fileReader.readAsDataURL(blob)
      window.addEventListener('message', event => {
        // 处理来自子窗口的消息
        console.log('Received message from child:', event.data);
        this.$router.go(-1)
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
  }
}
</script>
<style lang="scss" scoped>
/* 支付的样式 */
.pay iframe {
  background-color: var(--title-color);
  width: 100%;
  height: 900px;
}
</style>
